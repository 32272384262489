import Vue from 'vue/dist/vue.esm';
import I18n from 'i18n-js'; // eslint-disable-line no-unused-vars
import createProvider from './shared-vue';

Vue.component('AppFlashMessage', () => import('../main/flash-message.vue'));
Vue.component('AppLoginWrapper', () => import('../main/login-wrapper.vue'));
Vue.component('AppInteractiveVideo', () =>
  import('../main/interactive-video.vue'),
);
Vue.component('AppCardEssential', () =>
  import('../main/essential/card-essential.vue'),
);
Vue.component('AppResetEssentialProgress', () =>
  import('../main/essential/reset-essential-progress.vue'),
);
Vue.component('AppCardLearningPath', () =>
  import('../main/essential/card-learning-path.vue'),
);
Vue.component('AppPieChart', () => import('../main/pie-chart.vue'));
Vue.component('AppProgressIndicator', () =>
  import('../main/progress-indicator.vue'),
);
Vue.component('AppHeader', () => import('../main/headers/primary.vue'));
Vue.component('AppHeaderSecondary', () =>
  import('../main/headers/secondary.vue'),
);
Vue.component('AppCtaBlock', () => import('../main/cta-block.vue'));
Vue.component('AppHotspot', () => import('../main/hotspot.vue'));
Vue.component('AppNavigation', () => import('../main/layout/navigation.vue'));
Vue.component('AppFooter', () => import('../main/layout/footer.vue'));
Vue.component('AppFlipCards', () => import('../main/flip-cards.vue'));
Vue.component('AppWysiwygEditor', () => import('../main/wysiwyg-editor.vue'));
Vue.component('AppFeedback', () => import('../main/feedback.vue'));
Vue.component('AppToggleBookmark', () => import('../main/toggle-bookmark.vue'));
Vue.component('AppAwardChallengeRetry', () =>
  import('../main/award-challenge-retry.vue'),
);
Vue.component('AppCircleChart', () => import('../main/circle-chart.vue'));
Vue.component('AppCircleChartSimple', () =>
  import('../main/circle-chart-simple.vue'),
);
Vue.component('AppCardsGrid', () => import('../main/cards-grid.vue'));
Vue.component('AppCardsGridWithFilter', () =>
  import('../main/cards-grid-with-filter.vue'),
);
Vue.component('AppLazyImage', () => import('../main/lazy-image.vue'));
Vue.component('AppHorizontalChart', () =>
  import('../main/horizontal-chart.vue'),
);
Vue.component('AppAccordion', () => import('../main/accordion.vue'));
Vue.component('AppEssentialListItem', () =>
  import('../main/essential-list-item.vue'),
);
Vue.component('AppAchievementNotification', () =>
  import('../main/achievement-notification.vue'),
);
Vue.component('AppSlider', () => import('../main/slider.vue'));
Vue.component('AppSelectContent', () =>
  import('../main/select-items/select-content.vue'),
);
Vue.component('AppSelectTags', () =>
  import('../main/select-items/select-tags.vue'),
);
Vue.component('AppSelectSignOff', () =>
  import('../main/select-items/select-sign-off.vue'),
);
Vue.component('AppSelectAwardChallengeRetry', () =>
  import('../main/select-items/select-award-challenge-retry.vue'),
);
Vue.component('AppModal', () => import('../main/modal.vue'));
Vue.component('AppVideoPlayer', () => import('../main/video-player.vue'));
Vue.component('AppSelectGroup', () =>
  import('../main/select-items/select-group.vue'),
);
Vue.component('AppKnowledgeCheckReport', () =>
  import('../main/knowledge-check/knowledge-check-report.vue'),
);
Vue.component('AppTabParams', () => import('../main/tabs/tab-params.vue'));
Vue.component('AppImpersonateOverlay', () =>
  import('../main/impersonate-overlay.vue'),
);

document.addEventListener('DOMContentLoaded', () => {
  /* eslint-disable no-new */
  new Vue({
    el: '#app',
    apolloProvider: createProvider(),
  });
  /* eslint-enable no-new */
});
