import Vue from 'vue/dist/vue.esm';
import VueApollo from 'vue-apollo';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import Appsignal from '@appsignal/javascript';
import { errorHandler } from '@appsignal/vue';
import introspectionQueryResultData from '../../../config/fragment-types.json';
import i18n from '../mixins/i18n';

import '../i18n/translations'; // eslint-disable-line import/no-unresolved,import/extensions

// Configure AppSignal
const appSignApiKey = process.env.APP_SIGNAL_KEY;
if (appSignApiKey) {
  const appsignal = new Appsignal({
    key: appSignApiKey,
  });
  Vue.config.errorHandler = errorHandler(appsignal, Vue);
}

export default function createProvider() {
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  });

  const cache = new InMemoryCache({ fragmentMatcher });

  const httpLink = createHttpLink({
    uri: '/graphql',
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        'X-CSRF-TOKEN': document
          .querySelector("meta[name='csrf-token']")
          .getAttribute('content'),
      },
    };
  });

  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
  });

  Vue.use(VueApollo);
  Vue.mixin(i18n);

  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
  });

  return apolloProvider;
}
