import I18n from 'i18n-js';

const body = document.querySelector('body');
const { locale } = body.dataset;

I18n.locale = locale;

export default {
  methods: {
    t: (...args) => I18n.t(...args),
  },
};
